










// This component is typically used as a dynamic component inside ModalWrapperComponent
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import * as utils_tracker from '../utils_tracker';
import * as utils_ether from '../utils_ether';

@Component({
  name: 'ReferralCode',
  components: {}
})
export default class ReferralCodeComponent extends Vue {
  // prop reactive member variables publically available to the html template

  // public reactive member variables available to the html template
  public negativeButtonName: string = null;

  // public functions available to the html template
  public get loading(): boolean { return this.$store.state.loading }
  
  public address(): string { return utils_ether.getCurrentAccount() }

  public onCancel() {
    this.$emit('close', null)
  }

  public async onShow(dynamicProps: any) {
    if (dynamicProps && dynamicProps.negativeButtonName) this.negativeButtonName = dynamicProps.negativeButtonName
    if (!this.negativeButtonName) this.negativeButtonName = 'Ok'
  }
  

  // private, non-reactive member variables

  // private functions not available directly to HTML template
  private async mounted() { 
    if (this.$store.state.devmode) console.log(`${this.$options.name} mounted()`)

    utils_tracker.page(this.$options.name)
  }
  
  private async beforeDestroy() { if (this.$store.state.devmode) console.log(`${this.$options.name} beforeDestroy()`) }

}
